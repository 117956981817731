<template>
  <transition
    enter-active-class="transition ease-out duration-300"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-200"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div
      v-if="show"
      class="toast fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-red-600 text-white p-4 rounded-md shadow-lg z-50"
    >
      <span>{{ message }}</span>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, onUnmounted } from "vue";

const props = defineProps({
  message: String,
  duration: Number,
});

const show = ref(false);

onMounted(() => {
  show.value = true;
  setTimeout(() => {
    show.value = false;
  }, props.duration || 3000);
});

onUnmounted(() => {
  show.value = false;
});
</script>
