<template>
  <div
    :style="chatStyle"
    class="relative flex h-full max-w-full flex-1 flex-col overflow-hidden"
  >
    <main class="relative h-full w-full flex-1 overflow-auto transition-width">
      <div role="presentation" class="flex h-full flex-col">
        <div class="flex-1 overflow-hidden">
          <div ref="messagesContainer" class="messages-container h-full">
            <div
              class="flex flex-col pb-9 text-sm"
              :class="{ 'h-full': messages.value?.length === 0 }"
            >
              <ChatHeader @toggle-sidebar="() => $emit('toggle-sidebar')" />
              <MessagesComponent
                :messages="messages.value"
                @update:message="handleMessageUpdate"
              />
            </div>
          </div>
        </div>
        <MessageInput />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import ChatHeader from "./ChatHeader.vue";
import MessagesComponent from "./MessagesComponent.vue";
import MessageInput from "./MessageInput.vue";
import { defineComponent, ref, Ref, watch, onMounted, computed } from "vue";
import { useCollection, useCurrentUser } from "vuefire";
import { useStore } from "vuex";
import { db } from "@/firebase";
import { BotcenterMessage } from "@/models/message";
import { collection, orderBy, query } from "firebase/firestore";
import { OrganizationColorConfig } from "@/store";

function throttle<T, A, R>(func: (...args: A[]) => R, limit: number) {
  let lastFunc: number;
  let lastRan: number;
  return function (this: T) {
    const args = Array.from(arguments);
    if (!lastRan) {
      func.apply(this, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(this, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan)) as unknown as number;
    }
  };
}

export default defineComponent({
  name: "ChatComponent",
  components: { ChatHeader, MessagesComponent, MessageInput },
  setup() {
    const store = useStore();
    const user = useCurrentUser();
    const messagesContainer: Ref<Element | null> = ref(null);
    const colors = ref<OrganizationColorConfig | null>(null);

    const scrollToBottom = () => {
      if (messagesContainer.value) {
        const { scrollHeight } = messagesContainer.value;
        messagesContainer.value.scrollTop = scrollHeight;
      }
    };

    const throttledScrollToBottom = throttle(scrollToBottom, 100);

    const chatStyle = computed(() => {
      return {
        backgroundColor: colors.value?.secondary.main,
      };
    });

    const messages = computed(() => {
      const userId = user.value?.uid;
      if (!userId) {
        return [];
      }
      const sessionId = store.getters.currentSession;
      return useCollection(
        query(
          collection(db, `users/${userId}/sessions/${sessionId}/messages`),
          orderBy("timestamp", "asc")
        )
      );
    });

    watch(() => messages, throttledScrollToBottom, {
      deep: true,
      flush: "post",
    });

    watch(
      () => store.getters.processingMessage,
      (processingMessage) => {
        if (processingMessage) {
          throttledScrollToBottom();
        }
      },
      {
        flush: "post",
      }
    );

    function fetchColors() {
      const organizationConfig = store.getters.organizationConfig;
      colors.value = organizationConfig?.colors ?? null;
    }

    watch(
      () => store.getters.organizationConfig,
      () => {
        fetchColors();
      }
    );

    watch(
      () => store.getters.lastMessageContent,
      (lastMessageContent) => {
        if (lastMessageContent) {
          throttledScrollToBottom();
        }
      },
      {
        flush: "post",
      }
    );

    onMounted(() => {
      fetchColors();
      scrollToBottom();
    });

    const handleMessageUpdate = (updatedMessage: BotcenterMessage) => {
      store.dispatch("updateMessage", updatedMessage);
    };

    return {
      messages,
      messagesContainer,
      handleMessageUpdate,
      chatStyle,
    };
  },
});
</script>

<style scoped>
.messages-container {
  overflow-y: auto;
  scroll-behavior: smooth;
}
</style>
