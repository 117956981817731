import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full mt-3 pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)] relative" }
const _hoisted_2 = { class: "flex items-start justify-between mb-4" }
const _hoisted_3 = { class: "flex items-center justify-between mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      style: _normalizeStyle(_ctx.formStyle),
      class: "bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", {
          style: _normalizeStyle(_ctx.labelStyle),
          class: "block text-gray-700 text-md font-bold ml-2"
        }, " ¿Por qué has elegido esta valoración? (Opcional) ", 4),
        _createElementVNode("button", {
          style: _normalizeStyle(_ctx.closeButtonStyle),
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeForm && _ctx.closeForm(...args))),
          class: "text-gray-500 hover:text-gray-800 focus:outline-none mb-2"
        }, " X ", 4)
      ]),
      _withDirectives(_createElementVNode("textarea", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.feedbackComment) = $event)),
        id: "message-input",
        style: _normalizeStyle(_ctx.textareaStyle),
        class: _normalizeClass([_ctx.textareaClasses, "m-0 w-full border-2 py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 md:py-3.5 md:pr-12 pl-5 min-h-[60px] max-h-[500px]"]),
        ref: "textarea",
        placeholder: "Ingresa tus comentarios",
        rows: "3"
      }, null, 6), [
        [_vModelText, _ctx.feedbackComment]
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline",
          type: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.sendNegativeFeedback && _ctx.sendNegativeFeedback(...args)))
        }, " Enviar feedback "),
        _createElementVNode("button", {
          class: "bg-gray-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline",
          type: "button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.resetFeedback && _ctx.resetFeedback(...args)))
        }, " Restablecer ")
      ])
    ], 4)
  ]))
}