<template>
  <main
    v-if="user === null"
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm mb-10">
      <h2
        class="mt-10 text-center text-2xl font-semibold leading-9 tracking-tigh"
      >
        Inicia tu sesión con {{ assistantName }}
      </h2>
    </div>

    <div
      v-if="!loginWithEmail"
      class="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm"
    >
      <button
        @click="signinRedirect()"
        class="flex w-full justify-center rounded-md gsi-material-button"
      >
        <div class="gsi-material-button-state"></div>
        <div class="gsi-material-button-content-wrapper">
          <div class="gsi-material-button-icon">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              style="display: block"
            >
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              ></path>
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              ></path>
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              ></path>
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              ></path>
              <path fill="none" d="M0 0h48v48H0z"></path>
            </svg>
          </div>
          <span class="gsi-material-button-contents">Continuar con Google</span>
        </div>
      </button>
    </div>

    <div
      v-if="!loginWithEmail"
      class="flex justify-center mt-4 sm:mx-auto sm:w-full sm:max-w-sm"
    >
      <button
        @click="useEmail()"
        class="w-full px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
      >
        Continuar con Email
      </button>
    </div>

    <div
      v-if="loginWithEmail"
      class="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm"
    >
      <form
        @submit.prevent="signInWithEmail"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
            Email
          </label>
          <input
            v-model="email"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="text"
            placeholder="Email"
          />
        </div>
        <div class="mb-6">
          <label
            class="block text-gray-700 text-sm font-bold mb-2"
            for="password"
          >
            Password
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            :class="{ 'border-red-500': !password }"
            id="password"
            type="password"
            placeholder="******************"
            v-model="password"
          />
          <p v-if="!password" class="text-red-500 text-xs italic">
            Ingrese su contraseña por favor.
          </p>
        </div>
        <div class="flex items-center justify-between">
          <button
            class="bg-blue-500 disabled:bg-gray-500 disabled:hover:bg-gray-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
            :disabled="!email || !password"
          >
            Sign In
          </button>
        </div>
      </form>
    </div>
  </main>
  <ChatWidget v-if="user != null" />
  <ToastComponent v-if="showToast" :message="toastMessage" />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import ChatWidget from "./components/ChatWidget.vue";
import { GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { doc } from "firebase/firestore";
import { db } from "./firebase";
import { ASSISTANT_SYSTEM_NAME } from "./constants";

export const googleAuthProvider = new GoogleAuthProvider();

export default defineComponent({
  name: "App",
  components: {
    ChatWidget,
  },
});
</script>

<script setup lang="ts">
import {
  getRedirectResult,
  signInWithRedirect,
  signInWithCustomToken,
  signInWithPopup,
} from "firebase/auth";
import { useCurrentUser, useDocument, useFirebaseAuth } from "vuefire";
import ToastComponent from "./components/ToastComponent.vue";

const toastMessage = ref("");
const showToast = ref(false);

const user = useCurrentUser();
const userData = computed(() => {
  return useDocument(doc(db, `users/${user.value?.uid}`));
});
const auth = useFirebaseAuth();
const error = ref(null);
const store = useStore();
const route = useRoute();

const loginWithEmail = ref(false);
const email = ref("");
const password = ref("");

const assistantName = ref<string>(ASSISTANT_SYSTEM_NAME);

function signinRedirect() {
  signInWithRedirect(auth, googleAuthProvider).catch((reason) => {
    console.error("Failed signinRedirect", reason);
    error.value = reason;
  });
}

function signinPopup() {
  signInWithPopup(auth, googleAuthProvider).catch((reason) => {
    console.error("Failed signinPopup", reason);
    error.value = reason;
  });
}

async function signInWithEmail() {
  try {
    await signInWithEmailAndPassword(auth, email.value, password.value);
    // Handle successful sign in
  } catch (error) {
    console.error("Failed signInWithEmail", error);
    displayToast("" + error);
  }
}

watch(user, (newUser) => {
  if (newUser?.email) {
    // @ts-ignore
    window.clarity(
      "identify",
      newUser.email,
      newUser.uid,
      "copilot.adere.so",
      newUser.email
    );
  }
  store.commit("setCurrentUser", newUser);
});

watch(userData, (newUserData) => {
  store.commit("setCurrentUserData", newUserData);
});

function useEmail() {
  loginWithEmail.value = true;
}

function displayToast(message: string, duration = 3000) {
  toastMessage.value = message;
  showToast.value = true;
  setTimeout(() => {
    showToast.value = false;
  }, duration);
}

async function handleSignInFlow() {
  await auth.authStateReady();
  const jwt = route.query.jwt as string;
  if (jwt) {
    try {
      await signInWithCustomToken(auth, jwt);
      // Handle successful sign in with custom token
    } catch (error) {
      auth.signOut();
      window.parent.postMessage(
        {
          status: "authFailed",
          message: error.message,
        },
        "*"
      );
      continueSignInFlow();
    }
  } else {
    continueSignInFlow();
  }
}

async function continueSignInFlow() {
  if (auth.currentUser == null) {
    try {
      const result = await getRedirectResult(auth);
      if (result?.user) {
        console.log("Redirect result", result);
      }
    } catch (reason) {
      console.error("Failed getRedirectResult", reason);
      displayToast("" + reason);
    }
  }
}

onMounted(async () => {
  await handleSignInFlow();
});
</script>

<style scoped>
#app {
  height: 100%;
}

button {
  font-size: 14px;
  width: 220px;
}

.gsi-material-button {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>
