<template>
  <div class="px-4 py-2 justify-center text-base md:gap-6 m-auto">
    <div
      class="flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] group"
      :class="{ 'final-completion': isLast }"
    >
      <MessageAvatar
        :fromUser="message ? message.fromUser : false"
        :copilotIcon="copilotIcon"
      />
      <div
        class="relative flex w-full flex-col lg:w-[calc(100%-115px)] agent-turn"
      >
        <div :style="messageAuthorStyle" class="font-semibold select-none">
          {{
            message
              ? message.fromUser
                ? message.author.name
                : assistantName
              : assistantName
          }}
        </div>
        <div v-if="message" class="flex-col gap-1 md:gap-3">
          <MessageText :message="message" :shouldType="isLast && isRecent" />
          <AssistantMessageMenu
            v-if="!message.fromUser"
            :message="message"
            :isLast="isLast"
            @update:message="handleMessageUpdate"
            @toggle-negative-feedback-form="toggleNegativeFeedbackForm"
          />
          <UserMessageMenu v-if="message.fromUser" :message="message" />
          <NegativeFeedbackForm
            v-if="!message.fromUser"
            v-show="showNegativeFeedbackForm"
            :message="message"
            @update:message="handleMessageUpdate"
            @toggle-negative-feedback-form="toggleNegativeFeedbackForm"
          />
        </div>
        <div v-if="!message" class="flex-col gap-1 md:gap-3">
          <div class="flex flex-grow flex-col max-w-full">
            <div
              class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&]:mt-5 overflow-x-auto"
            >
              <div class="result-thinking">
                <div :style="loadingDotStyle" class="dot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { BotcenterMessage } from "../../models/message";
import MessageAvatar from "./MessageAvatar.vue";
import MessageText from "./MessageText.vue";
import AssistantMessageMenu from "./AssistantMessageMenu.vue";
import UserMessageMenu from "./UserMessageMenu.vue";
import NegativeFeedbackForm from "./NegativeFeedbackForm.vue";
import { useStore } from "vuex";
import { OrganizationColorConfig } from "@/store";
import { ASSISTANT_SYSTEM_NAME } from "@/constants";

export default defineComponent({
  name: "MessageItem",
  components: {
    MessageAvatar,
    MessageText,
    AssistantMessageMenu,
    UserMessageMenu,
    NegativeFeedbackForm,
  },
  props: {
    message: Object as PropType<BotcenterMessage>,
    isLast: Boolean,
    isRecent: Boolean,
    copilotIcon: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleMessageUpdate(updatedMessage: BotcenterMessage) {
      this.$emit("update:message", updatedMessage);
    },
  },
  setup() {
    const store = useStore();
    const showNegativeFeedbackForm = ref<boolean>(false);
    const colors = ref<OrganizationColorConfig | null>(null);
    const assistantName = ref<string>(ASSISTANT_SYSTEM_NAME);

    const fetchColors = () => {
      const organizationConfig = store.getters.organizationConfig;
      colors.value = organizationConfig?.colors ?? null;
    };

    const setAssistantName = () => {
      assistantName.value =
        store.getters.selectedBot?.name ?? ASSISTANT_SYSTEM_NAME;
    };

    const toggleNegativeFeedbackForm = (value?: boolean) => {
      if (showNegativeFeedbackForm.value === value) {
        return;
      }

      showNegativeFeedbackForm.value = value ?? !showNegativeFeedbackForm.value;
    };

    const messageAuthorStyle = computed(() => {
      return {
        color: colors.value?.secondary.text,
      };
    });

    const loadingDotStyle = computed(() => {
      return {
        backgroundColor: colors.value?.primary.main,
      };
    });

    watch(
      () => store.getters.selectedBot,
      () => {
        setAssistantName();
      }
    );

    watch(
      () => store.getters.organizationConfig,
      () => {
        fetchColors();
      }
    );

    onMounted(() => {
      setAssistantName();
      fetchColors();
    });

    return {
      messageAuthorStyle,
      assistantName,
      loadingDotStyle,
      showNegativeFeedbackForm,
      toggleNegativeFeedbackForm,
    };
  },
});
</script>

<style scoped>
.result-thinking {
  display: flex;
  align-items: center; /* Center the dot vertically */
  height: 24px; /* Adjust as needed */
}

.dot {
  margin-left: 4px;
  width: 10px; /* Size of the dot */
  height: 10px; /* Size of the dot */
  background-color: black; /* Color of the dot */
  border-radius: 50%; /* Make it round */
  animation: beat 1s infinite; /* Apply the animation */
}

@keyframes beat {
  0%,
  100% {
    transform: scale(1); /* Original size */
    opacity: 1; /* Fully visible */
  }
  50% {
    transform: scale(1.5); /* Increase size */
    opacity: 0.7; /* Slightly transparent */
  }
}
</style>
