<template>
  <div
    :style="sidebarColumnStyle"
    class="dark:bg-black flex-shrink-0 overflow-x-hidden bg-slate-300"
  >
    <div class="h-full w-[260px]">
      <div class="flex h-full min-h-0 flex-col">
        <div
          class="flex h-full min-h-0 flex-col transition-opacity opacity-100"
        >
          <div
            class="scrollbar-trigger relative h-full w-full flex-1 items-start border-white/20"
          >
            <nav class="flex h-full w-full flex-col px-3 pb-3.5">
              <div
                class="flex-col flex-1 transition-opacity duration-500 -mr-2 pr-2 overflow-y-auto"
              >
                <SidebarHeader />
                <div></div>
                <div
                  class="flex flex-col gap-2 pb-2 dark:text-gray-100 text-gray-800 text-sm"
                >
                  <div>
                    <span>
                      <div class="relative mt-2">
                        <div>
                          <h3
                            :style="chatsTitleStyle"
                            class="h-9 pb-2 pt-3 px-2 text-xs font-medium text-ellipsis overflow-hidden break-all bg-transparent text-gray-600"
                          >
                            Chats
                          </h3>
                          <div
                            v-if="showChatsAlertText"
                            class="inline-flex items-center gap-2"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M10 6.66677V9.81807M10 12.9491V12.9887M4.16667 15.8334H15.8333C16.3854 15.8296 16.8998 15.5525 17.2068 15.0936C17.5139 14.6347 17.5737 14.0536 17.3667 13.5418L11.45 3.33343C11.1565 2.8029 10.598 2.47363 9.99167 2.47363C9.38535 2.47363 8.82686 2.8029 8.53333 3.33343L2.61667 13.5418C2.41366 14.0415 2.4651 14.6087 2.75471 15.0638C3.04432 15.5189 3.53628 15.8057 4.075 15.8334"
                                stroke="#FFD540"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <p class="pb-2 pt-3 px-2 chats-alert-text">
                              {{ chatsAlertText }}
                            </p>
                          </div>
                        </div>
                        <ol>
                          <li
                            v-for="session in sessions"
                            :key="session.id"
                            class="relative z-[15]"
                          >
                            <div
                              @mouseover="setHoveredSession(session.id)"
                              @mouseleave="clearHoveredSession"
                              :style="getSessionItemStyle(session.id)"
                              class="cursor-pointer group relative rounded active:opacity-90 hover:bg-sky-500 hover:text-white"
                            >
                              <a
                                :href="'#/c/' + session.id"
                                class="flex items-center gap-2 p-2"
                              >
                                <div
                                  class="relative grow overflow-hidden whitespace-nowrap truncate"
                                >
                                  {{ session.description || "_" }}
                                </div>
                              </a>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <SidebarFooter />
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import SidebarHeader from "./SidebarHeader.vue";
import SidebarFooter from "./SidebarFooter.vue";
import { collection, query, orderBy } from "firebase/firestore";
import { useCollection, useCurrentUser } from "vuefire";
import { db } from "@/firebase";
import { mapGetters } from "vuex";
import { computed, ref } from "vue";
import useOrganizationConfig from "@/composables/UseOrganizationConfig";

export default {
  name: "ChatSidebar",
  components: { SidebarHeader, SidebarFooter },
  computed: {
    ...mapGetters(["processingMessage", "currentSession"]),
  },
  setup() {
    const user = useCurrentUser();
    const sessions = useCollection(
      query(
        collection(db, `users/${user.value.uid}/sessions`),
        orderBy("lastModified", "desc")
      )
    );
    const hoveredSessionId = ref<string | null>(null);
    const { organizationConfig } = useOrganizationConfig(user.value?.uid);

    const chatsAlertText = computed(() => {
      return organizationConfig?.value?.chatsAlertText;
    });

    const showChatsAlertText = computed(() => {
      return chatsAlertText.value && chatsAlertText.value?.length > 0;
    });

    const colors = computed(() => {
      return organizationConfig?.value?.colors;
    });

    const chatsTitleStyle = computed(() => {
      return { color: colors.value?.primary.text };
    });

    const sidebarColumnStyle = computed(() => {
      return { backgroundColor: colors.value?.primary.main };
    });

    const getSessionItemStyle = (sessionId: string) => {
      return {
        backgroundColor:
          hoveredSessionId.value === sessionId
            ? colors.value?.primary.hover
            : colors.value?.primary.main,
        color:
          hoveredSessionId.value === sessionId
            ? colors.value?.primary.hoverText
            : colors.value?.primary.text,
      };
    };

    const setHoveredSession = (sessionId: string) => {
      hoveredSessionId.value = sessionId;
    };

    const clearHoveredSession = () => {
      hoveredSessionId.value = null;
    };

    return {
      sessions,
      hoveredSessionId,
      setHoveredSession,
      clearHoveredSession,
      sidebarColumnStyle,
      getSessionItemStyle,
      chatsTitleStyle,
      chatsAlertText,
      showChatsAlertText,
    };
  },
};
</script>

<style scoped>
.chats-alert-text {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
</style>
