<template>
  <div class="flex flex-col pt-2 empty:hidden dark:border-white/20">
    <div class="flex w-full items-center">
      <div class="grow">
        <div v-if="!showProfileDropdown" class="p-4">
          <div
            class="relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-gray-700"
          >
            <div :style="profileButtonTextStyle" class="font-medium">
              {{ user.displayName }}
            </div>
            <div
              :style="profileButtonTextStyle"
              class="leading-5"
              :class="{
                'text-xs': user.displayName,
                'text-gray-500': user.displayName,
              }"
            >
              {{ user.email }}
            </div>
          </div>
        </div>
        <div v-else class="group relative" data-headlessui-state="">
          <div
            ref="dropdownMenu"
            v-if="isDropdownOpen"
            class="dropdown-menu absolute bottom-full right-0 bg-white border border-gray-300 rounded-lg p-2 z-20"
          >
            <a
              href="#"
              @click="logOut()"
              class="dropdown-item block px-3 py-2 text-gray-800 hover:bg-gray-100"
              >Cerrar sesión</a
            >
          </div>
          <button
            @mouseover="isProfileHovered = true"
            @mouseleave="isProfileHovered = false"
            :style="profileButtonStyle"
            ref="dropdownButton"
            @click="toggleDropdown"
            class="flex w-full items-center gap-2 rounded-lg p-2 text-sm hover:bg-gray-100 hover:bg-token-surface-primary group-ui-open:bg-gray-100 group-ui-open:bg-token-surface-primary dark:hover:bg-token-surface-primary dark:group-ui-open:bg-gray-800 dark:group-ui-open:bg-token-surface-primary"
            id="headlessui-menu-button-:r11s:"
            type="button"
            aria-haspopup="true"
            aria-expanded="false"
            data-headlessui-state=""
          >
            <div class="flex-shrink-0">
              <div
                class="flex items-center justify-center overflow-hidden rounded-full"
              >
                <div class="relative flex">
                  <img
                    v-if="user.photoURL"
                    alt="User"
                    loading="lazy"
                    width="32"
                    height="32"
                    decoding="async"
                    data-nimg="1"
                    class="rounded-sm"
                    :src="user.photoURL"
                    style="color: transparent"
                  />
                </div>
              </div>
            </div>
            <div
              class="relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-gray-700"
            >
              <div :style="profileButtonTextStyle" class="font-medium">
                {{ user.displayName }}
              </div>
              <div
                :style="profileButtonTextStyle"
                class="leading-5"
                :class="{
                  'text-xs': user.displayName,
                  'text-gray-500': user.displayName,
                }"
              >
                {{ user.email }}
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useFirebaseAuth, useCurrentUser } from "vuefire";
import { signOut } from "firebase/auth";
import { Ref, ref, onMounted, onBeforeUnmount, computed } from "vue";
import useOrganizationConfig from "@/composables/UseOrganizationConfig";

export default {
  name: "SidebarFooter",
  setup() {
    const user = useCurrentUser();
    const auth = useFirebaseAuth();
    const isDropdownOpen = ref(false);
    const isProfileHovered = ref(false);
    const dropdownButton: Ref<HTMLElement | null> = ref(null);
    const dropdownMenu: Ref<HTMLElement | null> = ref(null);
    const { organizationConfig } = useOrganizationConfig(user.value?.uid);

    function toggleDropdown() {
      isDropdownOpen.value = !isDropdownOpen.value;
    }

    function handleClickOutside(event: MouseEvent) {
      if (
        !dropdownButton.value?.contains(event.target as Node) &&
        !dropdownMenu.value?.contains(event.target as Node)
      ) {
        isDropdownOpen.value = false;
      }
    }

    const showProfileDropdown = computed(() => {
      return organizationConfig?.value?.showProfileDropdown ?? true;
    });

    const colors = computed(() => {
      return organizationConfig?.value?.colors ?? null;
    });

    const profileButtonStyle = computed(() => {
      return {
        backgroundColor: isProfileHovered.value
          ? colors.value?.primary.hover
          : colors.value?.primary.main,
        color: isProfileHovered.value
          ? colors.value?.primary.hoverText
          : colors.value?.primary.main,
      };
    });

    const profileButtonTextStyle = computed(() => {
      return {
        color: isProfileHovered.value
          ? colors.value?.primary.hoverText
          : colors.value?.primary.text,
      };
    });

    onMounted(() => {
      window.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("click", handleClickOutside);
    });

    function logOut() {
      signOut(auth);
    }

    return {
      user,
      isDropdownOpen,
      toggleDropdown,
      logOut,
      dropdownButton,
      dropdownMenu,
      isProfileHovered,
      profileButtonStyle,
      profileButtonTextStyle,
      showProfileDropdown,
    };
  },
};
</script>
