<template>
  <div class="relative z-0 flex h-full w-full overflow-hidden">
    <ChatSidebar v-if="isSidebarVisible" />
    <ChatComponent @toggle-sidebar="toggleSidebar" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import ChatSidebar from "./sidebar/ChatSidebar.vue";
import ChatComponent from "./ChatComponent.vue";
import { CURRENT_SESSION_STORAGE_KEY } from "@/constants";

export default defineComponent({
  name: "ChatWidget",
  components: {
    ChatSidebar,
    ChatComponent,
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const fetchSessionFromStorage = () => {
      return localStorage.getItem(CURRENT_SESSION_STORAGE_KEY);
    };

    const setSessionInStorage = (sessionId: string) => {
      localStorage.setItem(CURRENT_SESSION_STORAGE_KEY, sessionId);
    };

    const setCurrentSession = (sessionId: string) => {
      setSessionInStorage(sessionId);
      store.dispatch("setSession", sessionId);
    };

    // Update session based on URL hash
    watch(
      route,
      (currentRoute) => {
        const urlHash = currentRoute.hash;
        const segments = urlHash.split("/");
        if (segments.length > 2 && segments[1] == "c" && segments[2]) {
          const sessionId = segments[2];
          setCurrentSession(sessionId);
        } else {
          const sessionId = fetchSessionFromStorage();
          if (sessionId) {
            setCurrentSession(sessionId);
          }
        }
      },
      { immediate: true }
    );

    const screenWidth = ref(window.innerWidth);
    const breakpoint = 800;
    const isSidebarVisible = ref(screenWidth.value > breakpoint);

    const handleResize = () => {
      screenWidth.value = window.innerWidth;
      isSidebarVisible.value = screenWidth.value > breakpoint;
    };

    const toggleSidebar = () => {
      isSidebarVisible.value = !isSidebarVisible.value;
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return { isSidebarVisible, toggleSidebar };
  },
});
</script>
