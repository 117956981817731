<template>
  <div
    class="w-full pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)]"
  >
    <form
      class="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl xl:max-w-3xl"
    >
      <div class="relative flex h-full flex-1 items-stretch md:flex-col">
        <div class="flex w-full items-center">
          <div
            class="overflow-hidden [&:has(textarea:focus)]:border-token-border-xheavy [&:has(textarea:focus)]:shadow-[0_2px_6px_rgba(0,0,0,.05)] flex flex-col w-full dark:border-token-border-heavy flex-grow relative border border-token-border-heavy dark:text-white rounded-2xl bg-white dark:bg-gray-800 shadow-[0_0_0_2px_rgba(255,255,255,0.95)] dark:shadow-[0_0_0_2px_rgba(52,53,65,0.95)]"
          >
            <textarea
              id="message-input"
              class="m-0 w-full resize-none border-0 bg-transparent py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:py-3.5 md:pr-12 placeholder-black/50 dark:placeholder-white/50 pl-10 md:pl-[55px]"
              ref="textarea"
              v-model="newMessage"
              @input="autoExpand"
              @keydown.enter="handleEnterKey"
              placeholder="Escribe un mensaje..."
              rows="1"
            />
            <button
              class="absolute md:bottom-3 md:right-3 dark:hover:bg-gray-900 dark:disabled:hover:bg-transparent right-2 dark:disabled:bg-white disabled:bg-black disabled:opacity-10 disabled:text-gray-400 enabled:bg-black text-white p-0.5 border border-black rounded-lg dark:border-white dark:bg-white bottom-1.5 transition-colors"
              @click="sendMessage"
              :disabled="!newMessage.trim() || processingMessage"
            >
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="text-white dark:text-black"
                >
                  <g id="send">
                    <path
                      id="Vector"
                      d="M5 7L10.5 1.5M5 7L6.75004 10.5C6.79483 10.5978 6.8925 10.6604 7.00004 10.6604C7.10757 10.6604 7.20524 10.5978 7.25004 10.5L10.5 1.5M5 7L1.50004 5.25C1.40228 5.2052 1.3396 5.10753 1.3396 5C1.3396 4.89247 1.40228 4.7948 1.50004 4.75L10.5 1.5"
                      stroke="currentColor"
                      stroke-width="1"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { useCurrentUser } from "vuefire";
import { defineComponent, ref, Ref, onMounted, watch } from "vue";
import { useStore, mapGetters } from "vuex";
import { BotcenterMessage } from "../models/message";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  name: "MessageInput",
  computed: {
    ...mapGetters(["processingMessage", "currentSession"]),
  },
  setup() {
    const user = useCurrentUser();
    const store = useStore();
    const newMessage = ref(""); // Bound to the input box
    const textarea: Ref<HTMLTextAreaElement | null> = ref(null);
    const thresholdHeight = 24 + 14 * 2;

    const autoExpand = () => {
      if (textarea.value != null) {
        const scrollHeight = textarea.value?.scrollHeight;
        textarea.value.style.height = "auto";
        textarea.value.style.height =
          scrollHeight > thresholdHeight
            ? `${textarea.value.scrollHeight}px`
            : `${thresholdHeight}px`;
      }
    };

    const sendMessage = () => {
      const trimmedMessage = newMessage.value.trim();
      const sessionKey = store.getters.currentSession;
      const firebaseName = user.value.displayName;
      const databaseName = store.getters.currentUserData?.name;
      const authorName = firebaseName
        ? firebaseName
        : databaseName
        ? databaseName
        : user.value.email;
      if (trimmedMessage) {
        const author = {
          id: user.value.uid,
          name: authorName,
          identified: true,
          email: user.value.email,
          trustable: true,
          session_key: sessionKey,
        };
        const message: BotcenterMessage = {
          id: uuidv4(),
          type: "text",
          author: author,
          sender: author,
          payload: {
            text: trimmedMessage,
          },
          fromUser: true,
          timestamp: Date.now() / 1000,
        };
        // Emit an event with the message
        store.dispatch("sendMessage", message);
        newMessage.value = "";
      }
    };

    const handleEnterKey = (event: KeyboardEvent) => {
      if (!event.shiftKey && !store.state.processingMessage) {
        sendMessage();
        event.preventDefault(); // Prevents adding a new line
      }
    };

    const focusOnInput = () => {
      if (textarea.value) {
        textarea.value.focus();
      }
    };

    watch(
      () => store.getters.currentSession,
      () => {
        focusOnInput();
      }
    );

    onMounted(() => {
      focusOnInput();
    });

    return {
      newMessage,
      textarea,
      autoExpand,
      sendMessage,
      handleEnterKey,
    };
  },
});
</script>

<style scoped>
.message {
  margin-bottom: 5px;
  padding: 5px;
  background-color: #e9e9e9;
  border-radius: 4px;
}

.message-author {
  font-weight: bold;
}

#message-input {
  height: 52px;
  padding: 14px 55px 14px 20px;
  max-height: 200px;
  outline: none;
}
</style>
