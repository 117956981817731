import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7449424b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu mt-1 flex justify-start gap-3 empty:hidden" }
const _hoisted_2 = { class: "text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 visible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyMessageButton = _resolveComponent("CopyMessageButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CopyMessageButton, { message: _ctx.message }, null, 8, ["message"])
    ])
  ]))
}