import axios from "axios";
import { BotcenterMessage, MessageEvent } from "@/models/message";
import { User } from "@firebase/auth";

const instance = axios.create({
  baseURL: "https://api.gpt.adere.so/",
});

interface BotcenterResponse {
  messages: MessageEvent[];
}

class BotcenterService {
  public async sendMessage(
    message: BotcenterMessage,
    chatToken: string,
    user: User
  ): Promise<BotcenterMessage> {
    const userToken = await user.getIdToken();
    const messageClone = JSON.parse(JSON.stringify(message));
    messageClone["token"] = chatToken;
    const response = await instance.post(
      "chatbot/ask",
      {
        action: "message",
        body: messageClone,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    );
    const botcenterResponse = response.data as BotcenterResponse;
    const responseMessages: MessageEvent[] = botcenterResponse.messages;
    const botResponse = responseMessages[1].body;
    botResponse.previousMid = message.id;
    botResponse.receiver = message.sender.email;

    if (message.extraUserData) {
      botResponse.extraUserData = message.extraUserData;
    }
    return botResponse;
  }
}

export default new BotcenterService();
