<template>
  <div class="relative flex items-center">
    <div
      class="popover-content absolute z-10 bg-black text-white p-2 rounded-lg shadow-md max-w-xs whitespace-nowrap text-center text-sm -top-10 left-1/2 transform -translate-x-1/2"
      v-if="show"
    >
      <slot></slot>
    </div>
    <div @mouseover="show = true" @mouseleave="show = false">
      <slot name="button"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AdPopover",
  setup() {
    const show = ref(false);

    return { show };
  },
});
</script>
