<template>
  <div class="flex flex-grow flex-col max-w-full">
    <div
      class="min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&]:mt-5 overflow-x-auto"
    >
      <div
        v-if="!message.fromUser"
        v-html="htmlContent"
        :style="messageTextStyle"
        class="markdown prose w-full break-words dark:prose-invert light"
      ></div>
      <div
        v-if="message.fromUser"
        :style="messageTextStyle"
        class="markdown prose w-full break-words dark:prose-invert light"
      >
        {{ message.payload.text }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  watch,
  computed,
} from "vue";
import { useStore } from "vuex";
import { BotcenterMessage } from "../../models/message";
import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import hljs from "highlight.js";
import { OrganizationColorConfig } from "@/store";

function replaceSubstring(
  str: string,
  substring: string,
  newSubstring: string
) {
  return str.replace(new RegExp(escapeRegExp(substring), "g"), newSubstring);
}

// Helper function to escape special characters for regular expression
function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const marked = new Marked(
  markedHighlight({
    langPrefix: "hljs language-",
    highlight(code, lang) {
      const language = hljs.getLanguage(lang) ? lang : "plaintext";
      return hljs.highlight(code, { language }).value;
    },
  })
);

export default defineComponent({
  name: "MessageText",
  props: {
    message: {
      type: Object as PropType<BotcenterMessage>,
      required: true,
    },
    shouldType: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const markdownText = ref(props.message.payload.text);
    const animatedText = ref("");
    const htmlContent = ref("");
    const typingSpeed = 10; // Milliseconds between characters
    const colors = ref<OrganizationColorConfig | null>(null);

    function fetchColors() {
      const organizationConfig = store.getters.organizationConfig;
      colors.value = organizationConfig?.colors ?? null;
    }

    const messageTextStyle = computed(() => {
      return {
        color: colors.value?.secondary.text,
      };
    });

    async function setMarkdownToHtml(text: string) {
      const html = await marked.parse(text);
      let withoutTagNewlines = replaceSubstring(html, ">\n<", "><");
      withoutTagNewlines = withoutTagNewlines.replace(
        /<a /g,
        '<a target="_blank" '
      );
      htmlContent.value = withoutTagNewlines.trim();
      if (props.shouldType) {
        store.dispatch("setLastMessageContent", htmlContent.value);
      }
    }

    function animateMessage(text: string) {
      animatedText.value = "";
      let i = 0;
      const interval = setInterval(async () => {
        animatedText.value += text.charAt(i);
        await setMarkdownToHtml(animatedText.value);
        i++;
        if (i > text.length) {
          clearInterval(interval);
        }
      }, typingSpeed);
    }

    watch(
      () => store.getters.organizationConfig,
      () => {
        fetchColors();
      }
    );

    onMounted(async () => {
      fetchColors();
      if (!props.message.fromUser && markdownText.value) {
        if (props.shouldType) {
          await animateMessage(markdownText.value);
        } else {
          await setMarkdownToHtml(markdownText.value);
        }
      }
    });

    return {
      htmlContent,
      messageTextStyle,
    };
  },
});
</script>
