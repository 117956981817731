<template>
  <div class="flex-shrink-0 flex flex-col relative items-end">
    <div>
      <div class="pt-0.5">
        <div
          class="gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full"
        >
          <div class="relative flex">
            <img
              :alt="fromUser ? 'User' : assistantName"
              loading="lazy"
              width="24"
              height="24"
              decoding="async"
              data-nimg="1"
              class="rounded-sm"
              :src="
                fromUser
                  ? user.photoURL
                    ? user.photoURL
                    : placeholderImage
                  : copilotIcon
              "
              style="color: transparent"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ASSISTANT_SYSTEM_NAME } from "@/constants";
import { onMounted, ref, watch } from "vue";
import { useCurrentUser } from "vuefire";
import { useStore } from "vuex";

export default {
  props: {
    fromUser: Boolean,
    userIcon: String,
    copilotIcon: String,
  },
  data() {
    return {
      placeholderImage: require("@/assets/generic_avatar.png"),
    };
  },
  setup() {
    const store = useStore();
    const user = useCurrentUser();
    const assistantName = ref<string>(ASSISTANT_SYSTEM_NAME);

    const setAssistantName = () => {
      assistantName.value =
        store.getters.selectedBot?.name ?? ASSISTANT_SYSTEM_NAME;
    };

    watch(
      () => store.getters.selectedBot,
      () => {
        setAssistantName();
      }
    );

    onMounted(() => {
      setAssistantName();
    });

    return {
      user,
      assistantName,
    };
  },
};
</script>
