import { createStore } from "vuex";
import { BotcenterMessage } from "@/models/message";
import BotcenterService from "@/services/BotcenterService";
import markdownExample from "../assets/markdown/format.md";
import { Bot } from "@/models/bot";
import { v4 as uuidv4 } from "uuid";
import {
  doc,
  collection,
  addDoc,
  setDoc,
  CollectionReference,
  getDoc,
} from "firebase/firestore";
import { db } from "@/firebase";
import { User } from "firebase/auth";
import { CURRENT_SESSION_STORAGE_KEY } from "@/constants";

interface UserData {
  email: string;
  name?: string;
  organization?: string;
}

interface ColorConfig {
  main: string;
  text: string;
  hover: string;
  hoverText: string;
}

export interface OrganizationColorConfig {
  primary: ColorConfig;
  secondary: ColorConfig;
}

export interface OrganizationConfig {
  isActive: boolean;
  colors: OrganizationColorConfig;
  logoUrl: string;
  showProfileDropdown?: boolean;
  showBotSelector?: boolean;
  initialQuestionExample?: string;
  showFeedbackReminder?: boolean;
  chatsAlertText?: string;
}

type State = {
  lastMessageContent: string;
  processingMessage: boolean;
  selectedBot: Bot | null;
  currentSession: string;
  currentUser: User | null;
  currentUserData: UserData | null;
  organizationConfig: OrganizationConfig | null;
};

const sampleMessage = {
  author: {},
  sender: {},
  type: "text",
  payload: {
    text: markdownExample,
  },
  fromUser: false,
};

function getDemoExample(input?: string): string | null {
  if (input == "/ejemplo") {
    return markdownExample;
  } else {
    return null;
  }
}

async function getMessagesCollection(
  state: State
): Promise<CollectionReference> {
  const userId = state.currentUser?.uid;
  const sessionId = state.currentSession;
  return collection(db, `users/${userId}/sessions/${sessionId}/messages`);
}

async function updateSessionDoc(
  state: State,
  first_inbound_message: BotcenterMessage,
  first_outbound_message: BotcenterMessage
): Promise<void> {
  const sessionId = state.currentSession;
  const bot = state.selectedBot;
  const user = state.currentUser;
  if (!user || !bot || !sessionId) {
    throw new Error("User, bot or session not available");
  }
  const sessionRef = doc(db, `users/${user.uid}/sessions/${sessionId}`);
  await setDoc(
    sessionRef,
    {
      bot: bot,
      lastModified: Date.now(),
      first_inbound_message: first_inbound_message.payload.text,
      first_outbound_message: first_outbound_message.payload.text,
    },
    { merge: true }
  );
}

async function addMessageToFirestore(
  state: State,
  message: BotcenterMessage
): Promise<void> {
  const messagesCollection = await getMessagesCollection(state);
  await addDoc(messagesCollection, message);
}

async function upsertMessageInFirestore(
  state: State,
  message: BotcenterMessage
): Promise<void> {
  if (message.id) {
    const messagesCollection = await getMessagesCollection(state);
    const messageRef = doc(messagesCollection, message.id);
    await setDoc(messageRef, message);
  } else {
    await addMessageToFirestore(state, message);
  }
}

async function ensureUserDoc(user: User): Promise<void> {
  const userRef = doc(db, "users", user.uid);
  await setDoc(userRef, { email: user.email }, { merge: true });
}

async function getOrganizationConfig(
  organization: string
): Promise<OrganizationConfig | null> {
  try {
    const organizationDoc = (
      await getDoc(doc(db, "organizations", organization))
    ).data();
    return organizationDoc?.config?.isActive ? organizationDoc.config : null;
  } catch (err: unknown) {
    console.log(`Error while trying to get organization config: ${err}`);
  }

  return null;
}

async function getUserOrganization(uid: string): Promise<string | undefined> {
  try {
    const userDoc = (await getDoc(doc(db, "users", uid))).data();
    return userDoc?.organization;
  } catch (err: unknown) {
    console.log(`Error while trying to get user organization: ${err}`);
    return undefined;
  }
}

async function getExtraUserData(uid: string): Promise<object | undefined> {
  try {
    const userDoc = (await getDoc(doc(db, "users", uid))).data();
    return userDoc?.extraData;
  } catch (err: unknown) {
    console.log(
      `Error while trying to get extra user data for uid ${uid}: ${err}`
    );
    return;
  }
}

export default createStore({
  state: {
    messages: [],
    processingMessage: false,
    lastMessageContent: "",
    selectedBot: null,
    currentSession: uuidv4(),
    currentUser: null,
    currentUserData: null,
    organizationConfig: null,
  } as State,
  getters: {
    selectedBot: (state: State) => state.selectedBot,
    lastMessageContent: (state: State) => state.lastMessageContent,
    processingMessage: (state: State) => state.processingMessage,
    currentSession: (state: State) => state.currentSession,
    currentUser: (state: State) => state.currentUser,
    currentUserData: (state: State) => state.currentUserData,
    organizationConfig: (state: State) => state.organizationConfig,
  },
  mutations: {
    async addMessage(state: State, message: BotcenterMessage) {
      await upsertMessageInFirestore(state, message);
    },

    beginProcessingMessage(state: State) {
      state.processingMessage = true;
    },

    async addResponse(state: State, message: BotcenterMessage) {
      state.processingMessage = false;
      if (!message.fromUser && !message.receiver) {
        message.receiver = state.currentUser?.email;
      }

      await upsertMessageInFirestore(state, message);
    },

    setLastMessageContent(state: State, content: string) {
      state.lastMessageContent = content;
    },

    setSelectedBot(state: State, bot: Bot) {
      state.selectedBot = bot;
    },

    setSession(state: State, session: string) {
      state.currentSession = session;
    },

    async setCurrentUser(state: State, user: User | null) {
      state.currentUser = user;
      if (user) {
        await ensureUserDoc(user);
        const organization = await getUserOrganization(user.uid);
        if (organization) {
          state.organizationConfig = await getOrganizationConfig(organization);
        }
      }
    },

    async setCurrentUserData(state: State, user: UserData | null) {
      state.currentUserData = user;
    },
  },
  actions: {
    async selectBot({ state, commit, dispatch }, { bot, router }) {
      if (state.selectedBot?.token !== bot.token) {
        await dispatch("newConversation", { router });
      }
      await commit("setSelectedBot", bot);
    },

    async sendMessage({ state, commit }, message: BotcenterMessage) {
      if (state.currentUser && state.currentUser.uid) {
        const extraData = await getExtraUserData(state.currentUser.uid);
        if (extraData) {
          message.extraUserData = extraData;
        }
      } else {
        throw new Error("Current user is not available");
      }

      await commit("addMessage", message);
      await commit("beginProcessingMessage");

      let responseMessage: BotcenterMessage;
      const demoExample = getDemoExample(message.payload.text);
      if (demoExample) {
        responseMessage = {
          ...sampleMessage,
          timestamp: Date.now() / 1000,
        } as BotcenterMessage;
      } else if (state.selectedBot && state.selectedBot.token) {
        const startTime = performance.now();
        responseMessage = await BotcenterService.sendMessage(
          message,
          state.selectedBot.token,
          state.currentUser
        );
        responseMessage.latency = (performance.now() - startTime) / 1000;
      } else {
        throw new Error("Selected bot or token not available");
      }
      await commit("addResponse", responseMessage);
      await updateSessionDoc(state, message, responseMessage);
    },

    async updateMessage({ state }, message: BotcenterMessage) {
      if (message.id) {
        await upsertMessageInFirestore(state, message);
      } else {
        throw new Error("Message has no id");
      }
    },

    async setLastMessageContent({ commit }, content: string) {
      await commit("setLastMessageContent", content);
    },

    async newConversation({ dispatch }, { router }) {
      const sessionId = uuidv4();
      localStorage.setItem(CURRENT_SESSION_STORAGE_KEY, sessionId);
      await dispatch("setSession", sessionId);
      router.push({ hash: "" });
    },

    async setSession({ state, commit }, session: string) {
      if (state.currentSession !== session) {
        commit("setSession", session);
      }
    },
  },
  modules: {},
});
