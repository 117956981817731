import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import ChatWidget from "./components/ChatWidget.vue";

const routes: RouteRecordRaw[] = [
  { path: "/:pathMatch(.*)*", component: ChatWidget },
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
