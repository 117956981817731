import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-shrink-0 flex flex-col relative items-end" }
const _hoisted_2 = { class: "pt-0.5" }
const _hoisted_3 = { class: "gizmo-shadow-stroke flex h-6 w-6 items-center justify-center overflow-hidden rounded-full" }
const _hoisted_4 = { class: "relative flex" }
const _hoisted_5 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              alt: $props.fromUser ? 'User' : $setup.assistantName,
              loading: "lazy",
              width: "24",
              height: "24",
              decoding: "async",
              "data-nimg": "1",
              class: "rounded-sm",
              src: 
                $props.fromUser
                  ? $setup.user.photoURL
                    ? $setup.user.photoURL
                    : $data.placeholderImage
                  : $props.copilotIcon
              ,
              style: {"color":"transparent"}
            }, null, 8, _hoisted_5)
          ])
        ])
      ])
    ])
  ]))
}