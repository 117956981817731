<template>
  <div
    class="w-full mt-3 pt-2 md:pt-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:w-[calc(100%-.5rem)] relative"
  >
    <form
      :style="formStyle"
      class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 relative"
    >
      <div class="flex items-start justify-between mb-4">
        <label
          :style="labelStyle"
          class="block text-gray-700 text-md font-bold ml-2"
        >
          ¿Por qué has elegido esta valoración? (Opcional)
        </label>
        <button
          :style="closeButtonStyle"
          type="button"
          @click="closeForm"
          class="text-gray-500 hover:text-gray-800 focus:outline-none mb-2"
        >
          X
        </button>
      </div>
      <textarea
        v-model="feedbackComment"
        id="message-input"
        :style="textareaStyle"
        :class="textareaClasses"
        class="m-0 w-full border-2 py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 md:py-3.5 md:pr-12 pl-5 min-h-[60px] max-h-[500px]"
        ref="textarea"
        placeholder="Ingresa tus comentarios"
        rows="3"
      />
      <div class="flex items-center justify-between mt-4">
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          @click="sendNegativeFeedback"
        >
          Enviar feedback
        </button>
        <button
          class="bg-gray-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          @click="resetFeedback"
        >
          Restablecer
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { BotcenterMessage } from "@/models/message";
import { OrganizationColorConfig } from "@/store";
import {
  PropType,
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "NegativeFeedbackForm",
  components: {},
  computed: {},
  props: {
    message: {
      type: Object as PropType<BotcenterMessage>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const colors = ref<OrganizationColorConfig | null>(null);
    const feedbackComment = ref<string>(props.message.feedbackComment ?? "");

    const sendNegativeFeedback = () => {
      emit("update:message", {
        ...props.message,
        feedback: "negative",
        feedbackComment: feedbackComment.value,
      });
      emit("toggle-negative-feedback-form", false);
    };

    const closeForm = () => {
      emit("toggle-negative-feedback-form", false);
    };

    const resetFeedback = () => {
      emit("update:message", {
        ...props.message,
        feedback: null,
        feedbackComment: null,
      });
      feedbackComment.value = "";
      emit("toggle-negative-feedback-form", false);
    };

    const fetchColors = () => {
      const organizationConfig = store.getters.organizationConfig;
      colors.value = organizationConfig?.colors ?? null;
    };

    const formStyle = computed(() => {
      return {
        backgroundColor: colors.value?.primary.main,
      };
    });

    const labelStyle = computed(() => {
      return {
        color: colors.value?.primary.text,
      };
    });

    const closeButtonStyle = computed(() => {
      return {
        color: colors.value?.secondary.text,
      };
    });

    const textareaStyle = computed(() => {
      return {
        color: colors.value?.secondary.text,
        borderColor: colors.value?.secondary.main,
        backgroundColor: colors.value?.secondary.main,
      };
    });

    const textareaClasses = computed(() => {
      if (!colors.value) {
        return "placeholder-black/50 dark:placeholder-white/50";
      }
      return "";
    });

    watch(
      () => store.getters.organizationConfig,
      () => {
        fetchColors();
      }
    );

    onMounted(() => {
      fetchColors();
    });

    return {
      feedbackComment,
      sendNegativeFeedback,
      closeForm,
      resetFeedback,
      formStyle,
      labelStyle,
      textareaStyle,
      closeButtonStyle,
      textareaClasses,
    };
  },
});
</script>

<style scoped></style>
