import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43acc3ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 visible" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CopyMessageButton = _resolveComponent("CopyMessageButton")!
  const _component_AdPopover = _resolveComponent("AdPopover")!
  const _component_FeedbackButtons = _resolveComponent("FeedbackButtons")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["menu mt-1 flex justify-start gap-3 empty:hidden", { 'not-last': !_ctx.isLast }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_AdPopover, null, {
        button: _withCtx(() => [
          _createVNode(_component_CopyMessageButton, { message: _ctx.message }, null, 8, ["message"])
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Copiar ")
        ]),
        _: 1
      }),
      _createVNode(_component_FeedbackButtons, {
        message: _ctx.message,
        "onUpdate:message": _ctx.handleMessageUpdate,
        onToggleNegativeFeedbackForm: _ctx.toggleNegativeFeedbackForm
      }, null, 8, ["message", "onUpdate:message", "onToggleNegativeFeedbackForm"])
    ])
  ], 2))
}