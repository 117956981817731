<template>
  <div
    v-if="augmentedMessages?.length === 0"
    class="flex h-full flex-col items-center justify-center"
  >
    <h3 :style="helpTextStyle" class="mb-1 title-text">¿En qué te ayudo?</h3>
    <p :style="helpTextStyle" class="mb-1 description-text">
      Soy tu asistente de IA.
    </p>
    <div v-if="!showQuestionExample">
      <p :style="helpTextStyle" class="mb-1 description-text">
        Puedes hacerme consultas para facilitar tu atención a los clientes.
      </p>
    </div>
    <div v-else>
      <p :style="helpTextStyle" class="mb-1 description-text">
        Hazme consultas como:
      </p>
      <p :style="helpTextStyle" class="mb-1 question-example-text">
        {{ questionExample }}
      </p>
    </div>
  </div>
  <div
    v-for="message in augmentedMessages"
    :key="message.id"
    class="w-full text-token-text-primary"
  >
    <MessageItem
      :isLast="message.isLast"
      :isRecent="message.timestamp > Date.now() / 1000 - 60"
      :message="message"
      :copilotIcon="copilotIcon"
      @update:message="handleMessageUpdate"
    />
  </div>
  <div v-if="processingMessage" class="w-full text-token-text-primary">
    <MessageItem :copilotIcon="copilotIcon" />
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from "vue";
import { mapGetters } from "vuex";
import MessageItem from "./message/MessageItem.vue";
import { BotcenterMessage } from "@/models/message";
import useOrganizationConfig from "@/composables/UseOrganizationConfig";
import { useCurrentUser } from "vuefire";

interface AugmentedMessage extends BotcenterMessage {
  isLast: boolean;
}

export default defineComponent({
  name: "MessagesComponent",
  components: { MessageItem },
  computed: {
    ...mapGetters(["processingMessage"]),
    augmentedMessages(): AugmentedMessage[] | null {
      if (!this.messages) return null;
      return this.messages.map(
        (
          message: BotcenterMessage,
          index: number,
          array: BotcenterMessage[]
        ) => ({
          ...message,
          isLast: index === array.length - 1,
        })
      );
    },
  },
  setup() {
    const user = useCurrentUser();
    const { organizationConfig } = useOrganizationConfig(user.value?.uid);

    const copilotIcon = computed(() => {
      return (
        organizationConfig?.value?.logoUrl ?? require("@/assets/adereso.png")
      );
    });

    const showQuestionExample = computed(() => {
      return questionExample.value && questionExample.value.length > 0;
    });

    const questionExample = computed(() => {
      return organizationConfig?.value?.initialQuestionExample;
    });

    const colors = computed(() => {
      return organizationConfig?.value?.colors ?? null;
    });

    const helpTextStyle = computed(() => {
      return {
        color: colors.value?.secondary.text,
        textAlign: "center",
      };
    });

    return { copilotIcon, helpTextStyle, questionExample, showQuestionExample };
  },
  props: {
    messages: {
      type: Object as PropType<BotcenterMessage[]>,
      required: true,
    },
  },
  methods: {
    handleMessageUpdate(updatedMessage: BotcenterMessage) {
      this.$emit("update:message", updatedMessage);
    },
  },
});
</script>

<style scoped>
.title-text {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Rubik;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: -0.26px;
}

.description-text {
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.question-example-text {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
</style>
