<template>
  <div
    :style="sidebarHeaderStyle"
    class="sticky left-0 right-0 top-0 z-20 pt-3.5 bg-slate-300 dark:bg-black"
  >
    <div class="pb-0.5 last:pb-0">
      <a class="group flex h-10 items-center gap-2 rounded-lg font-medium">
        <div class="h-7 w-7 flex-shrink-0">
          <div
            class="relative flex h-full items-center justify-center bg-transparent"
          >
            <svg
              width="21"
              height="24"
              viewBox="0 0 21 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Icono">
                <path
                  id="Vector"
                  d="M13.2 6.22706V10.2239H9.73499C8.67126 10.2239 7.80093 11.0943 7.80093 12.158V17.493H1.93406C0.870329 17.493 0 16.6227 0 15.5589V6.22706C0 5.16333 0.870329 4.293 1.93406 4.293H6.86835C7.26664 5.24371 8.20648 5.91278 9.29983 5.91278C10.3932 5.91278 11.2992 5.26728 11.7089 4.34437C12.5611 4.54624 13.2 5.31564 13.2 6.22706Z"
                  fill="#0D7DD4"
                />
                <path
                  id="Vector_2"
                  d="M13.1991 10.2238V15.5588C13.1991 16.6225 12.3288 17.4929 11.265 17.4929H7.80005V12.1579C7.80005 11.0941 8.67038 10.2238 9.73411 10.2238H13.1991Z"
                  fill="#174385"
                />
                <path
                  id="Vector_3"
                  d="M21 12.1579V21.4897C21 22.5535 20.1297 23.4238 19.066 23.4238H9.73411C8.67038 23.4238 7.80005 22.5535 7.80005 21.4897V17.4929H11.265C12.3288 17.4929 13.1991 16.6225 13.1991 15.5588V10.2238H14.3541C14.5941 11.4308 15.662 12.3434 16.9385 12.3434C18.215 12.3434 19.2515 11.4568 19.512 10.2758C20.363 10.4789 21 11.2477 21 12.1579Z"
                  fill="#0D7DD4"
                />
                <g id="Group">
                  <path
                    id="Vector_4"
                    d="M10.6496 4.62763C11.3955 3.88177 11.3955 2.67249 10.6496 1.92663C9.90377 1.18078 8.6945 1.18078 7.94864 1.92663C7.20278 2.67249 7.20278 3.88177 7.94864 4.62763C8.6945 5.37348 9.90377 5.37348 10.6496 4.62763Z"
                    fill="#174385"
                  />
                  <path
                    id="Vector_5"
                    d="M9.30088 5.18748C8.62033 5.18748 8.02198 4.82968 7.68352 4.29298C7.49857 3.99864 7.39099 3.65051 7.39099 3.27759C7.39099 2.22413 8.24742 1.36771 9.30088 1.36771C10.3543 1.36771 11.2108 2.22413 11.2108 3.27759C11.2108 3.65051 11.1032 3.99864 10.9176 4.29298C10.5792 4.82968 9.98082 5.18748 9.30088 5.18748Z"
                    fill="#174385"
                  />
                </g>
                <path
                  id="Vector_6"
                  d="M18.75 10.3088C19.0835 9.30812 18.5426 8.22656 17.5419 7.89309C16.5412 7.55961 15.4596 8.10051 15.1261 9.10121C14.7927 10.1019 15.3336 11.1835 16.3343 11.517C17.335 11.8504 18.4165 11.3095 18.75 10.3088Z"
                  fill="#174385"
                />
              </g>
            </svg>
          </div>
        </div>
        <div
          :style="mainLogoStyle"
          class="grow overflow-hidden text-ellipsis whitespace-nowrap text-sm ad-brand-secondary cursor-default"
        >
          {{ assistantName }}
        </div>
        <div class="flex gap-3">
          <span class="flex items-center">
            <button
              @click="newChatButtonClicked"
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded"
              :disabled="processingMessage"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M5.00004 2.08334V7.91668M2.08337 5.00001H7.91671"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </span>
        </div>
      </a>
    </div>
    <!--<SidebarSearch />-->
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { OrganizationColorConfig } from "@/store";
import { ASSISTANT_SYSTEM_NAME } from "@/constants";
//import SidebarSearch from "./SidebarSearch.vue";

export default {
  name: "SidebarHeader",
  components: {
    /*SidebarSearch*/
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const processingMessage = computed(() => store.getters.processingMessage);
    const colors = ref<OrganizationColorConfig | null>(null);
    const assistantName = ref<string>(ASSISTANT_SYSTEM_NAME);

    const sidebarHeaderStyle = computed(() => {
      return {
        backgroundColor: colors.value?.primary.main,
      };
    });

    const mainLogoStyle = computed(() => {
      return { color: colors.value?.primary.text };
    });

    const newChatButtonClicked = async () => {
      await store.dispatch("newConversation", { router });
    };

    const fetchColors = () => {
      const organizationConfig = store.getters.organizationConfig;
      colors.value = organizationConfig?.colors ?? null;
    };

    watch(
      () => store.getters.organizationConfig,
      () => {
        fetchColors();
      }
    );

    onMounted(() => {
      fetchColors();
    });

    return {
      processingMessage,
      newChatButtonClicked,
      sidebarHeaderStyle,
      mainLogoStyle,
      assistantName,
    };
  },
};
</script>
