<template>
  <div
    class="menu mt-1 flex justify-start gap-3 empty:hidden"
    :class="{ 'not-last': !isLast }"
  >
    <div
      class="flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 visible"
    >
      <AdPopover>
        <template #button>
          <CopyMessageButton :message="message" />
        </template>
        Copiar
      </AdPopover>
      <FeedbackButtons
        :message="message"
        @update:message="handleMessageUpdate"
        @toggle-negative-feedback-form="toggleNegativeFeedbackForm"
      />
      <!-- <AdPopover v-if="isLast">
        <template #button>
          <RerunButton :message="message" />
        </template>
        Regenerar
      </AdPopover> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { BotcenterMessage } from "../../models/message";
import CopyMessageButton from "./CopyMessageButton.vue";
import FeedbackButtons from "./FeedbackButtons.vue";
//import RerunButton from "./RerunButton.vue";
import AdPopover from "../PopoverComponent.vue";

export default defineComponent({
  name: "AssistantMessageMenu",
  components: { CopyMessageButton, FeedbackButtons, AdPopover },
  props: {
    message: {
      type: Object as PropType<BotcenterMessage>,
      required: true,
    },
    isLast: Boolean,
  },
  methods: {
    handleMessageUpdate(updatedMessage: BotcenterMessage) {
      this.$emit("update:message", updatedMessage);
    },
    toggleNegativeFeedbackForm(value?: boolean) {
      this.$emit("toggle-negative-feedback-form", value);
    },
  },
});
</script>

<style scoped>
.not-last {
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
</style>
