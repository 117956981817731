import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative z-0 flex h-full w-full overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatSidebar = _resolveComponent("ChatSidebar")!
  const _component_ChatComponent = _resolveComponent("ChatComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isSidebarVisible)
      ? (_openBlock(), _createBlock(_component_ChatSidebar, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_ChatComponent, { onToggleSidebar: _ctx.toggleSidebar }, null, 8, ["onToggleSidebar"])
  ]))
}