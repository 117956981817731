import { db } from "@/firebase";
import { OrganizationConfig } from "@/store";
import { doc } from "firebase/firestore";
import { computed, onMounted, ref, watch } from "vue";
import { useDocument } from "vuefire";
import { useStore } from "vuex";

export default function useOrganizationConfig(userId: string) {
  const store = useStore();
  const user = useDocument(doc(db, `users/${userId}`));
  const organizationConfig = ref<OrganizationConfig | null>(null);

  const configuration = computed(() => {
    const organizationId = user.value?.organization;
    if (organizationId) {
      return useDocument(doc(db, `organizations/${organizationId}`));
    }
    return null;
  });

  watch(
    configuration,
    (newConfiguration) => {
      if (newConfiguration && newConfiguration.value) {
        organizationConfig.value = newConfiguration.value.config;
      }
    },
    { deep: true }
  );

  onMounted(() => {
    organizationConfig.value = {
      ...store.getters.organizationConfig,
    };
  });

  return { organizationConfig };
}
