import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bc9462ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex h-full flex-col items-center justify-center"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "w-full text-token-text-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageItem = _resolveComponent("MessageItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.augmentedMessages?.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", {
            style: _normalizeStyle(_ctx.helpTextStyle),
            class: "mb-1 title-text"
          }, "¿En qué te ayudo?", 4),
          _createElementVNode("p", {
            style: _normalizeStyle(_ctx.helpTextStyle),
            class: "mb-1 description-text"
          }, " Soy tu asistente de IA. ", 4),
          (!_ctx.showQuestionExample)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("p", {
                  style: _normalizeStyle(_ctx.helpTextStyle),
                  class: "mb-1 description-text"
                }, " Puedes hacerme consultas para facilitar tu atención a los clientes. ", 4)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", {
                  style: _normalizeStyle(_ctx.helpTextStyle),
                  class: "mb-1 description-text"
                }, " Hazme consultas como: ", 4),
                _createElementVNode("p", {
                  style: _normalizeStyle(_ctx.helpTextStyle),
                  class: "mb-1 question-example-text"
                }, _toDisplayString(_ctx.questionExample), 5)
              ]))
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.augmentedMessages, (message) => {
      return (_openBlock(), _createElementBlock("div", {
        key: message.id,
        class: "w-full text-token-text-primary"
      }, [
        _createVNode(_component_MessageItem, {
          isLast: message.isLast,
          isRecent: message.timestamp > Date.now() / 1000 - 60,
          message: message,
          copilotIcon: _ctx.copilotIcon,
          "onUpdate:message": _ctx.handleMessageUpdate
        }, null, 8, ["isLast", "isRecent", "message", "copilotIcon", "onUpdate:message"])
      ]))
    }), 128)),
    (_ctx.processingMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_MessageItem, { copilotIcon: _ctx.copilotIcon }, null, 8, ["copilotIcon"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}