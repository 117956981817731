import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyDCvyY5PtB417-h7A1apAJdDwC9bn14bPw",
  authDomain: "gpt.adereso.ai",
  projectId: "adereso-copilot",
  storageBucket: "adereso-copilot.appspot.com",
  messagingSenderId: "354164545514",
  appId: "1:354164545514:web:9d82d3b15bde6be0d58493",
});

export const db = getFirestore(firebaseApp);
