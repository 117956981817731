import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1864d140"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative h-full w-full flex-1 overflow-auto transition-width" }
const _hoisted_2 = {
  role: "presentation",
  class: "flex h-full flex-col"
}
const _hoisted_3 = { class: "flex-1 overflow-hidden" }
const _hoisted_4 = {
  ref: "messagesContainer",
  class: "messages-container h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatHeader = _resolveComponent("ChatHeader")!
  const _component_MessagesComponent = _resolveComponent("MessagesComponent")!
  const _component_MessageInput = _resolveComponent("MessageInput")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.chatStyle),
    class: "relative flex h-full max-w-full flex-1 flex-col overflow-hidden"
  }, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: _normalizeClass(["flex flex-col pb-9 text-sm", { 'h-full': _ctx.messages.value?.length === 0 }])
            }, [
              _createVNode(_component_ChatHeader, {
                onToggleSidebar: _cache[0] || (_cache[0] = () => _ctx.$emit('toggle-sidebar'))
              }),
              _createVNode(_component_MessagesComponent, {
                messages: _ctx.messages.value,
                "onUpdate:message": _ctx.handleMessageUpdate
              }, null, 8, ["messages", "onUpdate:message"])
            ], 2)
          ], 512)
        ]),
        _createVNode(_component_MessageInput)
      ])
    ])
  ], 4))
}