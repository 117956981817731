import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-grow flex-col max-w-full" }
const _hoisted_2 = { class: "min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&]:mt-5 overflow-x-auto" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.message.fromUser)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.htmlContent,
            style: _normalizeStyle(_ctx.messageTextStyle),
            class: "markdown prose w-full break-words dark:prose-invert light"
          }, null, 12, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.message.fromUser)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            style: _normalizeStyle(_ctx.messageTextStyle),
            class: "markdown prose w-full break-words dark:prose-invert light"
          }, _toDisplayString(_ctx.message.payload.text), 5))
        : _createCommentVNode("", true)
    ])
  ]))
}