<template>
  <span
    ><button
      class="flex items-center gap-1.5 rounded-md p-1 text-xs text-gray-400 hover:text-gray-950 dark:text-gray-400 dark:hover:text-gray-200 disabled:dark:hover:text-gray-400 md:invisible md:group-hover:visible md:group-[.final-completion]:visible"
      @click="copyToClipboard"
      type="button"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="icon-md"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z"
          fill="currentColor"
        ></path>
      </svg></button
  ></span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { BotcenterMessage } from "../../models/message";

export default defineComponent({
  name: "CopyMessageButton",
  components: {},
  computed: {},
  props: {
    message: Object as PropType<BotcenterMessage>,
  },
  methods: {
    copyToClipboard() {
      if (!this.message?.payload?.text) return;

      navigator.clipboard
        .writeText(this.message.payload.text)
        .then(() => {
          console.log("Message copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy message: ", err);
        });
    },
  },
});
</script>

<style scoped></style>
