<template>
  <div
    :style="headerStyle"
    class="sticky top-0 mb-1.5 flex items-center justify-between z-10 h-14 bg-slate-100 p-2 font-semibold dark:bg-gray-800 relative"
  >
    <div class="flex items-center gap-2 flex-grow">
      <button
        @click="toggleSidebar"
        class="w-6 h-6"
        v-if="isSidebarToggleVisible"
      >
        <svg
          v-if="isSidebarOpen"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
      <div v-if="showBotSelector">
        <div
          class="group flex cursor-pointer items-center gap-1 rounded-xl py-2 px-3 text-lg font-semibold hover:bg-slate-300 dark:hover:bg-black/10"
          :class="{ 'bg-slate-300': showDropdown }"
          @click="toggleDropdown"
        >
          {{ selectedBot ? selectedBot.name : "Cargando..." }}
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            class="text-token-text-tertiary"
          >
            <path
              d="M11.3346 7.83203L8.00131 11.1654L4.66797 7.83203"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </div>
        <div
          v-if="showDropdown"
          :style="dropdownStyle"
          class="absolute top-12 rounded bg-white shadow-lg"
        >
          <div
            v-for="bot in bots"
            :key="bot.id"
            class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
            @click="selectBot(bot)"
          >
            {{ bot.name }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="group flex items-center gap-1 rounded-xl py-2 px-3 text-lg font-semibold"
      >
        {{ selectedBot ? selectedBot.name : "Cargando..." }}
      </div>
    </div>
    <div
      class="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center p-2"
    >
      <div v-if="showFeedbackReminder" class="flex items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          class="inline-item"
        >
          <path
            d="M5.83333 9.16683V15.8335C5.83333 16.2937 5.46024 16.6668 5 16.6668H3.33333C2.8731 16.6668 2.5 16.2937 2.5 15.8335V10.0002C2.5 9.53993 2.8731 9.16683 3.33333 9.16683H5.83333C7.67428 9.16683 9.16667 7.67445 9.16667 5.8335V5.00016C9.16667 4.07969 9.91286 3.3335 10.8333 3.3335C11.7538 3.3335 12.5 4.07969 12.5 5.00016V9.16683H15C15.9205 9.16683 16.6667 9.91302 16.6667 10.8335L15.8333 15.0002C15.5871 16.0504 14.9076 16.7299 14.1667 16.6668H8.33333C6.95262 16.6668 5.83333 15.5475 5.83333 14.1668"
            stroke="#46B82E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p class="inline-item feedback-reminder">Recuerda dejar tu feedback</p>
      </div>
    </div>
    <div class="flex items-center gap-2 flex-grow"></div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  nextTick,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { Bot } from "@/models/bot";
import { useStore } from "vuex";
import { db } from "@/firebase";
import { DocumentReference, doc, getDoc } from "firebase/firestore";
import { useCurrentUser, useDocument } from "vuefire";
import { useRouter } from "vue-router";
import useOrganizationConfig from "@/composables/UseOrganizationConfig";

export default defineComponent({
  name: "ChatHeader",
  setup(_, { emit }) {
    const store = useStore();
    const router = useRouter();
    const selectedBot = ref(store.getters.selectedBot);
    const showDropdown = ref(false);
    const breakpoint = 800;
    const screenWidth = ref(window.innerWidth);
    const isSidebarOpen = ref(screenWidth.value > breakpoint);
    const isSidebarToggleVisible = ref(screenWidth.value <= breakpoint);
    const processingMessage = computed(() => store.getters.processingMessage);
    const currentHoveredBot = ref<string | null>(null);

    const handleResize = () => {
      screenWidth.value = window.innerWidth;
      isSidebarToggleVisible.value = screenWidth.value <= breakpoint;
    };

    const setSelectedBot = (bot: Bot) => {
      selectedBot.value = bot;
      store.commit("setSelectedBot", bot);
    };

    const selectBot = (bot: Bot) => {
      if (processingMessage.value) return;
      selectedBot.value = bot;
      showDropdown.value = false;
      store.dispatch("selectBot", { bot, router });
    };

    const authUser = useCurrentUser();
    const user = useDocument(doc(db, `users/${authUser.value?.uid}`));

    const { organizationConfig } = useOrganizationConfig(authUser.value?.uid);

    const showFeedbackReminder = computed(() => {
      return organizationConfig?.value?.showFeedbackReminder;
    });

    const session = computed(() => {
      return useDocument(
        doc(
          db,
          `users/${user.value?.id}/sessions/${store.getters.currentSession}`
        )
      );
    });

    const userPermissions = computed(() => {
      const path = `organizations/${user.value?.organization}/users/${user.value?.id}`;
      return useDocument(doc(db, path));
    });

    const bots = ref<Bot[]>([]);

    watch(
      userPermissions,
      async (userPermissions) => {
        if (userPermissions.value?.bots) {
          const botIds = userPermissions.value.bots;
          const botQueries: DocumentReference[] = botIds.map((botId: string) =>
            doc(db, `organizations/${user.value?.organization}/bots/${botId}`)
          );
          const botDocs = await Promise.all(
            botQueries.map((botQuery) => getDoc(botQuery))
          );
          bots.value = botDocs.map((botDoc) => botDoc.data() as Bot);
        } else {
          bots.value = [];
        }
      },
      { deep: true }
    );

    watch(
      [session, bots],
      async ([session, bots]) => {
        if (session.value?.bot) {
          setSelectedBot(session.value.bot);
        } else if (bots.length > 0 && !selectedBot.value) {
          await nextTick();
          setSelectedBot(bots[0]);
        }
      },
      { deep: true }
    );

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
      emit("toggle-sidebar");
    };

    const colors = computed(() => {
      return organizationConfig.value?.colors ?? null;
    });

    const showBotSelector = computed(() => {
      return organizationConfig.value?.showBotSelector ?? true;
    });

    const headerStyle = computed(() => {
      return {
        backgroundColor: colors.value?.secondary.main,
        color: colors.value?.primary.text,
      };
    });

    const dropdownStyle = computed(() => {
      return {
        backgroundColor: colors.value?.primary.main,
        color: colors.value?.primary.text,
      };
    });

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    return {
      processingMessage,
      bots,
      selectedBot,
      showDropdown,
      isSidebarToggleVisible,
      toggleDropdown,
      selectBot,
      isSidebarOpen,
      toggleSidebar,
      headerStyle,
      dropdownStyle,
      currentHoveredBot,
      showBotSelector,
      showFeedbackReminder,
    };
  },
});
</script>
<style scoped>
.feedback-reminder {
  font-feature-settings: "clig" off, "liga" off;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.inline-item {
  display: inline-block;
  vertical-align: middle;
}
</style>
