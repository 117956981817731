import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "relative flex items-center" }
const _hoisted_2 = {
  key: 0,
  class: "popover-content absolute z-10 bg-black text-white p-2 rounded-lg shadow-md max-w-xs whitespace-nowrap text-center text-sm -top-10 left-1/2 transform -translate-x-1/2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = true)),
      onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
    }, [
      _renderSlot(_ctx.$slots, "button")
    ], 32)
  ]))
}