<template>
  <div class="menu mt-1 flex justify-start gap-3 empty:hidden">
    <div
      class="text-gray-400 flex self-end lg:self-center justify-center lg:justify-start mt-0 -ml-1 visible"
    >
      <CopyMessageButton :message="message" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { BotcenterMessage } from "../../models/message";
import CopyMessageButton from "./CopyMessageButton.vue";

export default defineComponent({
  name: "UserMessageMenu",
  components: { CopyMessageButton },
  props: {
    message: Object as PropType<BotcenterMessage>,
  },
});
</script>

<style scoped>
.menu {
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
</style>
