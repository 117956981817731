import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col pt-2 empty:hidden dark:border-white/20" }
const _hoisted_2 = { class: "flex w-full items-center" }
const _hoisted_3 = { class: "grow" }
const _hoisted_4 = {
  key: 0,
  class: "p-4"
}
const _hoisted_5 = { class: "relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-gray-700" }
const _hoisted_6 = {
  key: 1,
  class: "group relative",
  "data-headlessui-state": ""
}
const _hoisted_7 = {
  key: 0,
  ref: "dropdownMenu",
  class: "dropdown-menu absolute bottom-full right-0 bg-white border border-gray-300 rounded-lg p-2 z-20"
}
const _hoisted_8 = { class: "flex-shrink-0" }
const _hoisted_9 = { class: "flex items-center justify-center overflow-hidden rounded-full" }
const _hoisted_10 = { class: "relative flex" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "relative -top-px grow -space-y-px overflow-hidden text-ellipsis whitespace-nowrap text-left text-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!$setup.showProfileDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", {
                  style: _normalizeStyle($setup.profileButtonTextStyle),
                  class: "font-medium"
                }, _toDisplayString($setup.user.displayName), 5),
                _createElementVNode("div", {
                  style: _normalizeStyle($setup.profileButtonTextStyle),
                  class: _normalizeClass(["leading-5", {
                'text-xs': $setup.user.displayName,
                'text-gray-500': $setup.user.displayName,
              }])
                }, _toDisplayString($setup.user.email), 7)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_6, [
              ($setup.isDropdownOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("a", {
                      href: "#",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.logOut())),
                      class: "dropdown-item block px-3 py-2 text-gray-800 hover:bg-gray-100"
                    }, "Cerrar sesión")
                  ], 512))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onMouseover: _cache[1] || (_cache[1] = ($event: any) => ($setup.isProfileHovered = true)),
                onMouseleave: _cache[2] || (_cache[2] = ($event: any) => ($setup.isProfileHovered = false)),
                style: _normalizeStyle($setup.profileButtonStyle),
                ref: "dropdownButton",
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.toggleDropdown && $setup.toggleDropdown(...args))),
                class: "flex w-full items-center gap-2 rounded-lg p-2 text-sm hover:bg-gray-100 hover:bg-token-surface-primary group-ui-open:bg-gray-100 group-ui-open:bg-token-surface-primary dark:hover:bg-token-surface-primary dark:group-ui-open:bg-gray-800 dark:group-ui-open:bg-token-surface-primary",
                id: "headlessui-menu-button-:r11s:",
                type: "button",
                "aria-haspopup": "true",
                "aria-expanded": "false",
                "data-headlessui-state": ""
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      ($setup.user.photoURL)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            alt: "User",
                            loading: "lazy",
                            width: "32",
                            height: "32",
                            decoding: "async",
                            "data-nimg": "1",
                            class: "rounded-sm",
                            src: $setup.user.photoURL,
                            style: {"color":"transparent"}
                          }, null, 8, _hoisted_11))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", {
                    style: _normalizeStyle($setup.profileButtonTextStyle),
                    class: "font-medium"
                  }, _toDisplayString($setup.user.displayName), 5),
                  _createElementVNode("div", {
                    style: _normalizeStyle($setup.profileButtonTextStyle),
                    class: _normalizeClass(["leading-5", {
                  'text-xs': $setup.user.displayName,
                  'text-gray-500': $setup.user.displayName,
                }])
                  }, _toDisplayString($setup.user.email), 7)
                ])
              ], 36)
            ]))
      ])
    ])
  ]))
}