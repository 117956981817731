import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39c54896"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-4 py-2 justify-center text-base md:gap-6 m-auto" }
const _hoisted_2 = { class: "relative flex w-full flex-col lg:w-[calc(100%-115px)] agent-turn" }
const _hoisted_3 = {
  key: 0,
  class: "flex-col gap-1 md:gap-3"
}
const _hoisted_4 = {
  key: 1,
  class: "flex-col gap-1 md:gap-3"
}
const _hoisted_5 = { class: "flex flex-grow flex-col max-w-full" }
const _hoisted_6 = { class: "min-h-[20px] text-message flex flex-col items-start gap-3 whitespace-pre-wrap break-words [.text-message+&]:mt-5 overflow-x-auto" }
const _hoisted_7 = { class: "result-thinking" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageAvatar = _resolveComponent("MessageAvatar")!
  const _component_MessageText = _resolveComponent("MessageText")!
  const _component_AssistantMessageMenu = _resolveComponent("AssistantMessageMenu")!
  const _component_UserMessageMenu = _resolveComponent("UserMessageMenu")!
  const _component_NegativeFeedbackForm = _resolveComponent("NegativeFeedbackForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex flex-1 text-base mx-auto gap-3 md:px-5 lg:px-1 xl:px-5 md:max-w-3xl lg:max-w-[40rem] xl:max-w-[48rem] group", { 'final-completion': _ctx.isLast }])
    }, [
      _createVNode(_component_MessageAvatar, {
        fromUser: _ctx.message ? _ctx.message.fromUser : false,
        copilotIcon: _ctx.copilotIcon
      }, null, 8, ["fromUser", "copilotIcon"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          style: _normalizeStyle(_ctx.messageAuthorStyle),
          class: "font-semibold select-none"
        }, _toDisplayString(_ctx.message
              ? _ctx.message.fromUser
                ? _ctx.message.author.name
                : _ctx.assistantName
              : _ctx.assistantName), 5),
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_MessageText, {
                message: _ctx.message,
                shouldType: _ctx.isLast && _ctx.isRecent
              }, null, 8, ["message", "shouldType"]),
              (!_ctx.message.fromUser)
                ? (_openBlock(), _createBlock(_component_AssistantMessageMenu, {
                    key: 0,
                    message: _ctx.message,
                    isLast: _ctx.isLast,
                    "onUpdate:message": _ctx.handleMessageUpdate,
                    onToggleNegativeFeedbackForm: _ctx.toggleNegativeFeedbackForm
                  }, null, 8, ["message", "isLast", "onUpdate:message", "onToggleNegativeFeedbackForm"]))
                : _createCommentVNode("", true),
              (_ctx.message.fromUser)
                ? (_openBlock(), _createBlock(_component_UserMessageMenu, {
                    key: 1,
                    message: _ctx.message
                  }, null, 8, ["message"]))
                : _createCommentVNode("", true),
              (!_ctx.message.fromUser)
                ? _withDirectives((_openBlock(), _createBlock(_component_NegativeFeedbackForm, {
                    key: 2,
                    message: _ctx.message,
                    "onUpdate:message": _ctx.handleMessageUpdate,
                    onToggleNegativeFeedbackForm: _ctx.toggleNegativeFeedbackForm
                  }, null, 8, ["message", "onUpdate:message", "onToggleNegativeFeedbackForm"])), [
                    [_vShow, _ctx.showNegativeFeedbackForm]
                  ])
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.message)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", {
                      style: _normalizeStyle(_ctx.loadingDotStyle),
                      class: "dot"
                    }, null, 4)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 2)
  ]))
}